.layout-box {
    height: 100%;
  
    :global {
      .ant-layout-sider-children {
        overflow: hidden;
      }
    }
  }
  
  .no-layout {
    height: 100%;
  }
  
  